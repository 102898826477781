import React, { useEffect, useState } from 'react'
import { QueryParamMessage } from '../../../components/shared'
import Layout from '../../../templates/LiveLayout'
import * as S from '../../../styles/login-live.styles'
import { PageRendererProps } from 'gatsby'
import * as session from '../../../util/session'
import { fetchLiveConfig } from '../../../util/fetch/workouts'
import { LiveConfig } from '../../../types'
import { withErrorHandler } from '../../../components/errorHandler'
import axios from 'axios'
import { X_CLIENT_NAME, API_ROOT } from '../../../services/request'
import { WorkoutField, Config } from '../edit-video'
import { CreateLiveWorkoutForm } from '../../../components/forms/CreateLiveWorkout'

const GATSBY_API_BASE_CLIENT =
  process.env.GATSBY_API_BASE_CLIENT === 'true' ? true : false

const CreateWorkout = (props: PageRendererProps): React.ReactElement => {
  const [liveConfig, setLiveConfig] = useState<LiveConfig | null>(null)
  const [workoutConfig, setWorkoutConfig] = useState<Config>(null)

  const getLiveConfig = async () => {
    const response = await fetchLiveConfig()
    setLiveConfig(response)
  }

  useEffect(() => {
    const fetchWorkoutConfig = () => {
      axios
        .get(`${API_ROOT}/config/workout`, {
          headers: {
            'x-client-name': X_CLIENT_NAME,
          },
        })
        .then(({ data }: { data: WorkoutField[] }) => {
          // @ts-ignore
          const config: Config = {}
          data.map(item => {
            config[item.field] = item
          })
          setWorkoutConfig(config)
        })
    }
    if (GATSBY_API_BASE_CLIENT) {
      getLiveConfig()
    }
    fetchWorkoutConfig()
  }, [])

  const isTrainer = session.isTrainer()

  if (!isTrainer) {
    return <>{'To access this url log in as trainer'}</>
  }

  const tags = GATSBY_API_BASE_CLIENT
    ? liveConfig?.liveWorkoutTags
    : workoutConfig?.tags?.values

  return (
    <Layout title="Schedule workout" location={props.location}>
      <S.Container>
        <S.Wrap>
          <QueryParamMessage />
          <CreateLiveWorkoutForm
            submitText="Submit"
            submitTextPending="Submiting..."
            types={tags || []}
            liveConfig={liveConfig}
          />
        </S.Wrap>
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(CreateWorkout)
